import {
  colorSteelLightest,
  layoutWidth1200,
  mediaTabletLandscape,
  sizeLarge,
  sizeMedium,
  sizeSmall,
  sizeXlarge,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import { useState } from "react";

import ThematicBreak from "../ThematicBreak";
import FacetMenu from "./FacetMenu";
import FilterControls from "./FilterControls";
import type { Facet } from "./types";

interface Props {}

const getLimit = (expanded: boolean, attribute: string) => {
  switch (attribute) {
    // We filter "Nature Communications" out of
    // the journal list. This makes Algolia display
    // one less item than is expected. So we need
    // to ask for an extra item to account for that.
    case "journal":
      return expanded ? 16 : 6;
    case "productGroups":
      return expanded ? 15 : 10;

    default:
      // Normal limits are 5 items collapsed,
      // 15 when expanded.
      return expanded ? 15 : 7;
  }
};

const Filters: FunctionComponent<Props> = () => {
  const [expanded, setExpanded] = useState(false);
  const handleToggleButtonClick = () => setExpanded(!expanded);

  const [facets] = useState<Facet[]>([
    {
      attribute: "tags",
      title: "AREA OF INTEREST",
    },
    {
      attribute: "species",
      title: "SPECIES",
    },
    {
      attribute: "tissueTypes",
      title: "TISSUE TYPE",
    },
    {
      attribute: "journal",
      title: "JOURNAL",
    },
    {
      attribute: "productGroups",
      title: "10X GENOMICS PRODUCT",
    },
  ]);

  return (
    <div
      css={css`
        background-color: ${colorSteelLightest};
        box-sizing: border-box;
        display: block;
        margin-bottom: ${sizeLarge};
        min-height: 256px;
        padding: 0 ${sizeXlarge};
        white-space: nowrap;
        width: 100%;

        @media (max-width: ${mediaTabletLandscape}) {
          display: none;
        }

        :last-of-type {
          box-sizing: border-box;
          padding: ${sizeLarge} 0;
        }
      `}
    >
      <div
        css={css`
          box-sizing: border-box;
          margin: 0 auto;
          max-width: ${layoutWidth1200};
          padding: ${sizeLarge} 0;
        `}
      >
        <div
          css={css`
            align-items: flex-start;
            box-sizing: border-box;
            display: flex;
            gap: ${sizeSmall};
            justify-content: space-between;
            margin-bottom: ${sizeMedium};
            padding: 0 ${sizeLarge};
          `}
        >
          {facets.map((facet) => {
            const { attribute, title } = facet;
            return (
              <FacetMenu
                attribute={attribute}
                key={attribute}
                limit={getLimit(expanded, attribute)}
                title={title}
                transformItems={(items) =>
                  // https://10xtech.atlassian.net/browse/WEB-115
                  // Exclude "Nature Communications".
                  // It is deemed insufficiently credible.
                  items.filter(
                    (item: any) => item.label !== "Nature Communications",
                  )
                }
              />
            );
          })}
        </div>

        <div
          css={css`
            margin-bottom: ${sizeLarge};
          `}
        >
          <ThematicBreak />
        </div>

        <FilterControls
          expanded={expanded}
          onToggleButtonClick={handleToggleButtonClick}
        />
      </div>
    </div>
  );
};

export default Filters;
