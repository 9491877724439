import type { FunctionComponent } from "react";

import Modal from "../Modal";
import ArticleInfo from "./ArticleInfo";

interface Props {
  hit: any;
  onClose: (hit: any) => void;
}

const ArticleModal: FunctionComponent<Props> = ({ hit, onClose }) => {
  return (
    <Modal handleClose={onClose}>
      <ArticleInfo expanded={true} hit={hit} />
    </Modal>
  );
};

export default ArticleModal;
