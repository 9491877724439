import {
  borderRadiusMedium,
  sizeLarge,
  sizeMedium,
  sizeSmall,
  sizeXlarge,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Icon from "../Icon";
import Paragraph from "../Paragraph";

interface Props {
  content?: string;
}

const ExecutiveSummary: FunctionComponent<Props> = ({ content }) =>
  content ? (
    <div
      css={css`
        background-color: #f2f9ff;
        box-sizing: border-box;
        border-radius: ${borderRadiusMedium};
        display: flex;
        margin-bottom: ${sizeLarge};
        min-height: ${sizeXlarge};
        padding-top: ${sizeSmall};
        padding-bottom: ${sizeSmall};
        padding-left: ${sizeMedium};
        padding-right: ${sizeMedium};

        > * {
          margin: 0;
        }
      `}
    >
      <div>
        <Icon
          color={"blue"}
          css={css`
            margin-right: 16px;
          `}
          size={"22px"}
          source={"lightbulb"}
          yPos={"4px"}
        />
      </div>

      <Paragraph
        css={css`
          margin-bottom: 0;
        `}
        size={"medium"}
      >
        {content}
      </Paragraph>
    </div>
  ) : null;

export default ExecutiveSummary;
