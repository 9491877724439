import { publicationsIndexName } from "@10x/algolia-utils/index-names";
import getSearchOnlyClient from "@10x/algolia-utils/searchOnlyClient";
import { useRouter } from "next/router";
import { parse } from "qs";
import type { FunctionComponent } from "react";
import { useState } from "react";
import type { InstantSearchProps } from "react-instantsearch-dom";
import { InstantSearch } from "react-instantsearch-dom";

import { getHref } from "../../utils";
import Filters from "./Filters";
import SearchBox from "./SearchBox";
import SearchHits from "./SearchHits";
import Stats from "./Stats";

type AlgoliaProps = Pick<InstantSearchProps, "widgetsCollector">;

type Props = {
  onSearchParameters?: any;
} & AlgoliaProps;

const PublicationSearch: FunctionComponent<Props> = ({
  onSearchParameters,
  widgetsCollector,
}) => {
  const router = useRouter();

  /*
  Parses the query string from the url using qs.

  Why parse the query from the path when useRouter()
  already provides router.query? Because useRouter()
  mishandles nested query values, which causes nested
  parts of search state to be ignored.

  For example, this -

  refinementList: {
    productGroups: ['Single Cell Gene Expression']
  }

  - is returned by useRouter as this:

  "refinementList[productGroups][0]": "Single Cell Gene Expression"

  InstantSearch ignores this, of course, thereby de-applying
  any currently applied filters/facets. Other aspects of
  search state are similarly affected.

  This is a known, long-standing issue with Next.js:
  https://github.com/zeit/next.js/pull/2605#issuecomment-343774156
  We're expected to bring our own handling for nesting in query strings.
  */
  const getParsedQuery = () => {
    const [, query] = router.asPath.split("?");
    return parse(query);
  };

  const [searchState, setSearchState] = useState(getParsedQuery());
  const [debounce, setDebounce] = useState<NodeJS.Timeout | null>(null);

  const handleClose = () => {
    const updatedSearchState = getParsedQuery();

    // Clear pmid from the query so that the modal closes.
    delete updatedSearchState.pmid;

    const href = getHref("/publications", updatedSearchState);
    router.push(href, href, { shallow: true });

    // Clear pmid from searchState so that zombie modals do not appear.
    setSearchState(updatedSearchState);
  };

  const handleSearchStateChange = (updatedSearchState: Record<string, any>) => {
    debounce && clearTimeout(debounce);

    // Update router
    const href = getHref(router.pathname, updatedSearchState);
    setDebounce(
      setTimeout(() => {
        router.push(href, href, {
          scroll: false,
          shallow: true,
        });
      }, 400),
    );

    // Update searchState
    setSearchState(updatedSearchState);
  };

  return (
    <section className={"PublicationSearch"}>
      <InstantSearch
        indexName={publicationsIndexName.toString()}
        onSearchParameters={onSearchParameters}
        onSearchStateChange={handleSearchStateChange}
        searchClient={getSearchOnlyClient()}
        searchState={searchState}
        widgetsCollector={widgetsCollector}
      >
        <SearchBox />
        <Filters />
        <Stats />
        <SearchHits onClose={handleClose} />
      </InstantSearch>
    </section>
  );
};

export default PublicationSearch;
