import {
  borderRadiusMedium,
  boxShadowLight,
  colorBlueLightest,
  colorBlueMedium,
  colorSteelDarkest,
  colorSteelLight,
  colorSteelLightest,
  colorWhite,
  fontFamilyBase,
  fontLetterSpacingLarge,
  fontSizeLarge,
  fontSizeMedium,
  layoutWidth1200,
  mediaTabletLandscape,
  sizeLarge,
  sizeXlarge,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import { useState } from "react";
import { connectSearchBox } from "react-instantsearch-dom";

import Icon from "../Icon";
import IconButton from "../IconButton";

interface Props {
  currentRefinement: string;
  isSearchStalled: boolean;
  refine: (value: string) => void;
}

const SearchBox: FunctionComponent<Props> = ({ currentRefinement, refine }) => {
  const [focused, setFocused] = useState(false);

  return (
    <form
      action={""}
      css={css`
        background: linear-gradient(
          ${colorWhite} 50%,
          ${colorSteelLightest} 50%
        );
        width: 100%;

        @media (max-width: ${mediaTabletLandscape}) {
          background: ${colorBlueLightest};
          padding-bottom: ${sizeLarge};
        }
      `}
      noValidate={true}
      onSubmit={(event) => event.preventDefault()}
      role={"search"}
    >
      <div
        css={css`
          box-sizing: border-box;
          height: 80px;
          margin: 0 auto;
          max-width: ${layoutWidth1200};

          @media (max-width: ${mediaTabletLandscape}) {
            height: 64px;
          }
        `}
      >
        <div
          css={css`
            align-items: center;
            background-color: ${colorWhite};
            border: 1px solid ${focused ? colorBlueMedium : colorSteelLight};
            border-radius: ${borderRadiusMedium};
            box-shadow: ${boxShadowLight};
            box-sizing: border-box;
            display: flex;
            gap: ${sizeLarge};
            height: 100%;
            padding-left: ${sizeXlarge};
            padding-right: ${sizeXlarge};

            @media (max-width: ${mediaTabletLandscape}) {
              margin-left: 1.5rem;
              margin-right: 1.5rem;
              padding-left: 1rem;
              padding-right: 1rem;
            }
          `}
        >
          <Icon
            color={focused ? "blue" : "base"}
            size={"22px"}
            source={"search"}
          />

          <input
            autoCapitalize={"off"}
            autoComplete={"off"}
            autoCorrect={"off"}
            css={css`
              -moz-appearance: none;
              -webkit-appearance: none;
              background: none;
              border: none;
              color: ${colorSteelDarkest};
              font-family: ${fontFamilyBase};
              font-size: ${fontSizeLarge};
              height: 100%;
              letter-spacing: ${fontLetterSpacingLarge};
              outline: none;
              width: 100%;

              [placeholder] {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              ::placeholder {
                color: ${colorSteelLight};
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              ::-webkit-search-cancel-button {
                display: none;
              }

              @media (max-width: ${mediaTabletLandscape}) {
                font-size: ${fontSizeMedium};

                [placeholder] {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            `}
            name={"Publication search"}
            onBlur={() => setFocused(false)}
            onChange={(event) => refine(event.currentTarget.value)}
            onFocus={() => setFocused(true)}
            onKeyPress={(event) => {
              // Don't clear search on enter
              if (event.key === "Enter") {
                event.preventDefault();
              }
            }}
            placeholder={"Search abstracts, sample types, and more"}
            spellCheck={false}
            type={"search"}
            value={currentRefinement}
          />

          {currentRefinement ? (
            <IconButton
              css={css`
                pointer-events: all;
              `}
              onClick={() => refine("")}
            >
              <Icon
                color={"blue"}
                size={"16px"}
                source={"close"}
                yPos={"2px"}
              />
            </IconButton>
          ) : null}
        </div>
      </div>
    </form>
  );
};

export default connectSearchBox(SearchBox);
