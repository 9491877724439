import { sizeMedium } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { useRouter } from "next/router";
import type { FunctionComponent } from "react";

import { getHref } from "../../utils";
import Anchor from "../Anchor";
import Heading from "../Heading";

interface Props {
  enabled: boolean;
  pmid: string;
  text: string;
}

const ArticleTitle: FunctionComponent<Props> = ({
  enabled = false,
  pmid,
  text,
}) => {
  const { pathname, query } = useRouter();
  const href = getHref(pathname, {
    ...query,
    pmid,
  });

  return (
    <>
      {enabled ? (
        <Heading
          as={"h1"}
          css={css`
            margin-bottom: ${sizeMedium};
          `}
          size={"large"}
          weight={"medium"}
        >
          <Anchor color={"inherit"} href={href} scroll={false}>
            {text}
          </Anchor>
        </Heading>
      ) : (
        <Heading as={"h1"} size={"large"}>
          {text}
        </Heading>
      )}
    </>
  );
};

export default ArticleTitle;
