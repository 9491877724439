import type { FunctionComponent } from "react";

import Heading from "../Heading";
import Paragraph from "../Paragraph";
import ThematicBreak from "../ThematicBreak";

interface Props {
  expanded: boolean;
  text: string;
}

const Abstract: FunctionComponent<Props> = ({ expanded = false, text }) => {
  return expanded ? (
    <>
      <ThematicBreak />
      <Heading as={"h2"} color={"gray"} size={"xsmall"} weight={"regular"}>
        {"ABSTRACT"}
      </Heading>
      <Paragraph size={"medium"}>{text}</Paragraph>
    </>
  ) : null;
};

export default Abstract;
