import { colorSteelDarkest, sizeXsmall } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../Anchor";
import Text from "../Text";
import { getProduct } from "./utils";

interface Props {
  expanded: boolean;
  groups: Array<string>;
}

const ProductGroups: FunctionComponent<Props> = ({ expanded, groups }) => {
  return (
    <div
      css={css`
        align-items: ${expanded ? "flex-start" : "center"};
        display: flex;
        flex-direction: ${expanded ? "column" : "row"};
        gap: ${sizeXsmall};
      `}
    >
      {groups.map((group) => {
        const product = getProduct(group);
        return (
          <Anchor
            aria-label={group}
            color={"gray"}
            css={css`
              align-items: center;
              display: flex;
            `}
            hoverColor={colorSteelDarkest}
            href={product.link}
            key={group}
            target={"_blank"}
          >
            {/*
              Omit labels when there is more than one group,
              and the component is not expanded.
            */}
            {groups.length > 1 && !expanded ? null : (
              <Text as={"span"} color={"inherit"} size={"small"}>
                {group}
              </Text>
            )}
          </Anchor>
        );
      })}
    </div>
  );
};

export default ProductGroups;
