const products: Record<
  string,
  {
    link: string;
    slug: string;
  }
> = {
  ["In Situ Gene Expression"]: {
    link: "/products/xenium-panels",
    slug: "in-situ-gene-expression",
  },
  ["Linked-Reads Genomics"]: {
    link: "/products/linked-reads",
    slug: "linked-reads",
  },

  ["Single Cell ATAC"]: {
    link: "/products/single-cell-atac/",
    slug: "single-cell-atac",
  },

  ["Single Cell CNV"]: {
    link: "/products/single-cell-cnv",
    slug: "single-cell-cnv",
  },

  ["Single Cell Gene Expression"]: {
    link: "/products/single-cell-gene-expression",
    slug: "single-cell-gene-expression",
  },

  ["Single Cell Gene Expression Flex"]: {
    link: "/products/single-cell-gene-expression-flex",
    slug: "single-cell-gene-expression-flex",
  },

  ["Single Cell Immune Profiling"]: {
    link: "/products/single-cell-immune-profiling",
    slug: "single-cell-immune-profiling",
  },

  ["Single Cell Multiome ATAC + Gene Expression"]: {
    link: "/products/single-cell-multiome-atac-plus-gene-expression",
    slug: "single-cell-multiome-atac-plus-gene-expression",
  },

  ["Spatial Gene Expression for FFPE"]: {
    link: "/products/spatial-gene-expression",
    slug: "spatial-gene-expression-ffpe",
  },

  ["Spatial Gene Expression for Fresh Frozen"]: {
    link: "/products/spatial-gene-expression",
    slug: "spatial-gene-expression",
  },
};

export const getProduct = (name: string) => {
  return {
    ...products[name],
    name,
  };
};
