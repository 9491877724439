import { mediaTabletLandscape, sizeXlarge } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { format } from "date-fns";
import type { FunctionComponent } from "react";

import Text from "../Text";
import Authors from "./Authors";
import type { Author } from "./types";

interface Props {
  authors: Array<Author>;
  date: string;
  expanded: boolean;
  journal: string;
}

const Citation: FunctionComponent<Props> = ({
  authors = [],
  date,
  expanded = false,
  journal,
}) => {
  return (
    <cite
      className={"Citation"}
      css={css`
        display: flex;
        font-style: normal;
        gap: ${sizeXlarge};
        margin-bottom: 1.5rem;

        @media (max-width: ${mediaTabletLandscape}) {
          gap: 1.5rem;
        }
      `}
    >
      <Text as={"span"} color={"gray"} size={"small"}>
        {`${journal}, ${format(new Date(date), "MMM yyyy")}`}
      </Text>

      {expanded ? null : <Authors authors={authors} expanded={expanded} />}
    </cite>
  );
};

export default Citation;
