import {
  colorSteelDarker,
  fontLetterSpacingCaps,
  sizeMedium,
  sizeXxsmall,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Heading from "../Heading";
import Text from "../Text";

interface Props {
  fields: Array<{
    label: string;
    value: string;
  }>;
}

const FieldList: FunctionComponent<Props> = ({ fields }) => (
  <ul
    css={css`
      padding: 0;
    `}
  >
    {fields.map((field) => {
      const { label, value } = field;
      return value ? (
        <li
          css={css`
            list-style: none;
            margin-bottom: ${sizeMedium};

            :last-of-type {
              margin: 0;
            }
          `}
          key={label}
        >
          <Heading
            as={"h2"}
            color={"gray"}
            css={css`
              letter-spacing: ${fontLetterSpacingCaps};
              margin-bottom: ${sizeXxsmall};
            `}
            size={"xsmall"}
            weight={"regular"}
          >
            {label}
          </Heading>
          <Text
            as={"p"}
            css={css`
              color: ${colorSteelDarker};
            `}
            size={"small"}
          >
            {value}
          </Text>
        </li>
      ) : null;
    })}
  </ul>
);

export default FieldList;
