import {
  colorSteelDarkest,
  mediaTabletLandscape,
  sizeMedium,
  sizeXlarge,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../Anchor";
import Text from "../Text";
import ProductGroups from "./ProductGroups";

interface Props {
  doi?: string;
  expanded: boolean;
  groups: Array<string>;
  pmid: string;
}

const References: FunctionComponent<Props> = ({
  doi,
  expanded,
  groups,
  pmid,
}) => {
  return (
    <div
      css={css`
        align-items: ${expanded ? "flex-end" : "center"};
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 1.5rem;
        margin-top: auto;
        width: 100%;

        @media (max-width: ${mediaTabletLandscape}) {
          align-items: flex-start;
          flex-direction: column;
          gap: ${sizeMedium};
        }
      `}
    >
      <ProductGroups expanded={expanded} groups={groups} />

      <cite
        css={css`
          display: flex;

          @media (max-width: ${mediaTabletLandscape}) {
            flex-direction: column;
            gap: ${sizeMedium};
          }
        `}
      >
        {doi ? (
          <Text
            as={"span"}
            color={"gray"}
            css={css`
              font-style: normal;
              margin-right: ${sizeXlarge};
              text-decoration: underline;
            `}
            size={"small"}
            weight={"regular"}
          >
            <Anchor
              color={"inherit"}
              hoverColor={colorSteelDarkest}
              href={`https://doi.org/${doi}`}
              target={"_blank"}
            >{`DOI: ${doi}`}</Anchor>
          </Text>
        ) : null}

        <Text
          as={"span"}
          color={"gray"}
          css={css`
            font-style: normal;
            text-decoration: underline;
          `}
          size={"small"}
          weight={"regular"}
        >
          <Anchor
            color={"inherit"}
            hoverColor={colorSteelDarkest}
            href={`https://pubmed.ncbi.nlm.nih.gov/${pmid}`}
            target={"_blank"}
          >{`PubMed: ${pmid}`}</Anchor>
        </Text>
      </cite>
    </div>
  );
};

export default References;
