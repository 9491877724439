import {
  borderRadiusMedium,
  borderStandard,
  boxShadowStandard,
  colorBlueLightest,
  colorWhite,
  sizeMedium,
  sizeSmall,
  sizeXsmall,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import { useState } from "react";
import { connectSortBy } from "react-instantsearch-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { Manager, Popper, Reference } from "react-popper";
import { useKey } from "react-use";

import Text from "../Text";
import ToggleButton from "../ToggleButton";

interface Props {
  defaultRefinement: string;
  items: Array<{
    isRefined?: boolean;
    label: string;
    value: string;
  }>;
  refine: (value: string) => void;
}

const SortBy: FunctionComponent<Props> = ({ items, refine }) => {
  const [expanded, setExpanded] = useState(false);
  const handleClick = () => setExpanded(!expanded);
  const handleOutsideClick = () => setExpanded(false);

  useKey("Escape", () => setExpanded(false));

  const refinedItem = items.find((item) => item.isRefined);
  const refinedItemLabel = refinedItem && refinedItem.label;
  const referenceLabel = refinedItemLabel
    ? `Sorted by: ${refinedItemLabel}`
    : "Sort by:";

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <div>
        <Manager>
          <Reference>
            {({ ref }) => (
              <ToggleButton
                aria-expanded={expanded}
                aria-haspopup={true}
                onClick={handleClick}
                selected={expanded}
              >
                <Text as={"span"} color={"blue"} ref={ref} size={"xsmall"}>
                  {referenceLabel}
                </Text>
                <Text as={"span"} color={"blue"} ref={ref} size={"xsmall"}>
                  {referenceLabel}
                </Text>
              </ToggleButton>
            )}
          </Reference>

          {expanded ? (
            <Popper placement={"bottom-end"}>
              {({ ref, style, placement }) => {
                return (
                  <ul
                    css={css`
                      background-color: ${colorWhite};
                      border: ${borderStandard};
                      border-radius: ${borderRadiusMedium};
                      box-shadow: ${boxShadowStandard};
                      margin: 0;
                      min-width: 192px;
                      padding-bottom: ${sizeXsmall};
                      padding-left: 0;
                      padding-right: 0;
                      padding-top: ${sizeXsmall};
                      z-index: 10;
                    `}
                    data-placement={placement}
                    ref={ref}
                    style={{
                      ...style,
                      top: sizeSmall,
                    }}
                  >
                    {items.map((item) => {
                      const { isRefined, label, value } = item;
                      return (
                        <li
                          css={css`
                            cursor: pointer;
                            height: 3rem;
                            list-style: none;

                            :hover {
                              background-color: ${colorBlueLightest};
                            }
                          `}
                          key={value}
                        >
                          <button
                            css={css`
                              align-items: center;
                              background: none;
                              border: none;
                              display: flex;
                              height: 100%;
                              padding-left: ${sizeMedium};
                              padding-right: ${sizeMedium};
                              text-align: left;
                              width: 100%;
                            `}
                            onClick={(event) => {
                              event.preventDefault();
                              refine(value);
                              setExpanded(false);
                            }}
                          >
                            <Text
                              as={"span"}
                              size={"small"}
                              weight={isRefined ? "semibold" : "regular"}
                            >
                              {label}
                            </Text>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                );
              }}
            </Popper>
          ) : null}
        </Manager>
      </div>
    </OutsideClickHandler>
  );
};

export default connectSortBy(SortBy);
