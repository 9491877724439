import {
  borderStandard,
  colorWhite,
  layoutWidth1200,
  mediaTabletLandscape,
  sizeLarge,
  sizeMedium,
  sizeXlarge,
  sizeXsmall,
  sizeXxlarge,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { useRouter } from "next/router";
import type { FunctionComponent } from "react";
import type { InfiniteHitsProvided } from "react-instantsearch-core";
import { connectInfiniteHits } from "react-instantsearch-dom";

import Block from "../Blocks/_Block";
import Intersection from "../Intersection";
import { useInfiniteScroll } from "../Search/hooks";
import ArticleInfo from "./ArticleInfo";
import ArticleModal from "./ArticleModal";
import type { SearchHit } from "./types";

type Props = InfiniteHitsProvided<SearchHit> & {
  onClose: () => void;
};

const SearchHits: FunctionComponent<Props> = ({
  hasMore,
  hits = [],
  onClose,
  refineNext,
}) => {
  const router = useRouter();

  const { intersectionRef } = useInfiniteScroll(hasMore, refineNext);

  return hits.length ? (
    <Block as={"div"} useHorizontalPaddingAtLowerWidths={false}>
      <ul
        css={css`
          box-sizing: border-box;
          margin: 0 auto;
          margin-bottom: ${sizeXxlarge};
          max-width: ${layoutWidth1200};
          padding: 0;
        `}
      >
        {hits.map((hit) => {
          const { pmid } = hit;
          const isSelected = pmid && String(pmid) === String(router.query.pmid);

          return pmid ? (
            <li
              /*
              Do not remove this className.
              It is used to test infinite scroll.
              */
              className={"SearchHit"}
              css={css`
                background-color: ${colorWhite};
                border: ${borderStandard};
                box-sizing: border-box;
                display: flex;
                list-style: none;
                margin-bottom: ${sizeXsmall};
                padding: ${sizeLarge} ${sizeXlarge};
                position: relative;

                @media (max-width: ${mediaTabletLandscape}) {
                  padding: ${sizeMedium} ${sizeLarge};
                }
              `}
              key={pmid}
            >
              <ArticleInfo hit={hit} />

              {isSelected ? <ArticleModal hit={hit} onClose={onClose} /> : null}
            </li>
          ) : null;
        })}

        <Intersection ref={intersectionRef} />
      </ul>
    </Block>
  ) : null;
};

export default connectInfiniteHits<Props, SearchHit>(SearchHits);
