import { sizeLarge, sizeXlarge, sizeXxlarge } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import ClearFiltersButton from "./ClearFiltersButton";
import ToggleFiltersButton from "./ToggleFiltersButton";
import ToggleSelectedPublications from "./ToggleSelectedPublications";

interface Props {
  expanded: boolean;
  onToggleButtonClick: () => void;
}

const FilterControls: FunctionComponent<Props> = ({
  expanded,
  onToggleButtonClick,
}) => {
  return (
    <div
      css={css`
        align-items: center;
        display: flex;
        height: ${sizeLarge};
        padding-left: ${sizeXlarge};
        padding-right: ${sizeXlarge};
      `}
    >
      <div
        css={css`
          display: flex;
          gap: ${sizeXlarge};
          margin-right: ${sizeXxlarge};
          width: 70%;
        `}
      >
        <ToggleFiltersButton
          onClick={onToggleButtonClick}
          selected={expanded}
        />
        <ClearFiltersButton />
      </div>
      <div className={"FilterControlsRight"}>
        <ToggleSelectedPublications
          attribute={"selected"}
          label={"Show Only Featured Publications"}
          value={true}
        />
      </div>
    </div>
  );
};

export default FilterControls;
