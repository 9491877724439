import type { FunctionComponent } from "react";

import Heading from "../Heading";
import Text from "../Text";
import type { Author } from "./types";

interface Props {
  authors: Array<Author>;
  expanded: boolean;
}

const Authors: FunctionComponent<Props> = ({ authors, expanded }) => {
  const getAuthors = (authors: Array<Author>) => {
    return authors
      .map((author) => {
        const { foreName, lastName } = author;
        return `${foreName} ${lastName}`;
      })
      .join(", ");
  };

  const getPrimaryAuthor = (authors: Array<Author>) => {
    const hasMultipleAuthors = authors.length > 1;
    const [primaryAuthor] = authors;
    const { foreName, lastName } = primaryAuthor;
    const author = `${foreName} ${lastName}`;
    return hasMultipleAuthors ? `${author} et al.` : author;
  };

  return (
    <>
      {expanded ? (
        <>
          <Heading
            as={"h2"}
            color={"gray"}
            responsive={true}
            size={"small"}
            weight={"regular"}
          >
            {"AUTHORS"}
          </Heading>
          <Text as={"p"} color={"gray"} size={"small"}>
            {getAuthors(authors)}
          </Text>
        </>
      ) : (
        <Text as={"span"} color={"gray"} size={"small"}>
          {getPrimaryAuthor(authors)}
        </Text>
      )}
    </>
  );
};

export default Authors;
