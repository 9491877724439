import { mediaTabletLandscape } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import { memo } from "react";

import Abstract from "./Abstract";
import Aside from "./ArticleInfoAside";
import ArticleTitle from "./ArticleTitle";
import Authors from "./Authors";
import Citation from "./Citation";
import ExecutiveSummary from "./ExecutiveSummary";
import References from "./References";
import type { SearchHit } from "./types";
import ViewOnPubmedButton from "./ViewOnPubmedButton";

interface Props {
  expanded?: boolean;
  hit: SearchHit;
}

const getField = (items?: Array<string>) => {
  return items && items.length ? items.join(", ") : "";
};

const getSampleTypes = (tissueTypes: Array<string>, inputType?: string) => {
  let sampleTypes = "";

  if (tissueTypes && tissueTypes.length) {
    sampleTypes = tissueTypes.join(", ");
  }

  if (sampleTypes && inputType) {
    sampleTypes = `${inputType} from ${sampleTypes}`;
  }

  return sampleTypes;
};

const ArticleInfo: FunctionComponent<Props> = ({ expanded = false, hit }) => {
  const {
    abstract,
    authors,
    date,
    doi,
    ePubDate,
    executiveSummary,
    inputType,
    journal,
    pmid,
    productGroups,
    species,
    tags,
    tissueTypes,
    title,
  } = hit;

  const fields = [
    { label: "AREA OF INTEREST", value: getField(tags) },
    { label: "SPECIES", value: getField(species) },
    { label: "SAMPLE TYPES", value: getSampleTypes(tissueTypes, inputType) },
  ];

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        width: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          max-width: 672px;
          min-height: 192px;
          width: 100%;
        `}
      >
        <Citation
          authors={authors}
          date={ePubDate || date}
          expanded={expanded}
          journal={journal}
        />

        <ArticleTitle enabled={!expanded} pmid={pmid} text={title} />

        {/* This will only appear at smaller screen widths. */}
        {expanded ? (
          <div
            css={css`
              display: none;

              @media (max-width: ${mediaTabletLandscape}) {
                display: ${expanded ? "initial" : "none"};
              }
            `}
          >
            <ViewOnPubmedButton pmid={pmid} />
          </div>
        ) : null}

        <ExecutiveSummary content={executiveSummary} />

        <References
          doi={doi}
          expanded={expanded}
          groups={productGroups}
          pmid={pmid}
        />

        {typeof abstract === "string" ? (
          <Abstract expanded={expanded} text={abstract} />
        ) : null}

        {expanded ? <Authors authors={authors} expanded={expanded} /> : null}
      </div>

      {/* This will not appear at smaller screen widths. */}
      <Aside expanded={expanded} fields={fields} label={title} pmid={pmid} />
    </div>
  );
};

export default memo(ArticleInfo);
