import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Button from "../Button";
import Icon from "../Icon";
import Text from "../Text";

interface Props {
  pmid: string;
}

const ViewOnPubmedButton: FunctionComponent<Props> = ({ pmid }) => {
  // Uses window.open because we want a new tab on a third party site
  const handleClick = () =>
    window.open(
      `https://pubmed.ncbi.nlm.nih.gov/${pmid}`,
      "_blank",
      "noopener,noreferrer",
    );

  return (
    <Button onClick={handleClick}>
      <Icon
        color={"white"}
        css={css`
          margin-right: 1.25rem;
        `}
        size={"18px"}
        source={"external-link"}
        yPos={"2px"}
      />
      <Text as={"span"} color={"white"} size={"medium"} weight={"semibold"}>
        {"View on PubMed"}
      </Text>
    </Button>
  );
};

export default ViewOnPubmedButton;
