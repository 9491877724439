import { getIndexNames } from "@10x/algolia-utils/publications/get-index-names";
import type { SortOptions } from "@10x/ui/src/PublicationSearch";
import {
  colorBlueLightest,
  layoutWidth1200,
  mediaTabletLandscape,
  sizeLarge,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { useRouter } from "next/router";
import type { FunctionComponent } from "react";
import { useState } from "react";
import { connectStats } from "react-instantsearch-dom";

import Block from "../Blocks/_Block";
import Text from "../Text";
import SortBy from "./SortBy";

interface Props {
  nbHits: number;
}

const Stats: FunctionComponent<Props> = ({ nbHits }) => {
  const { query } = useRouter();

  // Nasty: query.query. But:
  // The next.js router calls the query, er, "query",
  // and Algolia calls its primary param "query".
  // So here we are.
  const searchQuery = query.query;
  const numResults = `${nbHits} RESULTS`;
  const nameResults = searchQuery ? ` FOR "${searchQuery}"` : "";

  const [indexNames] = useState(getIndexNames());
  const [sortOptions] = useState<SortOptions>([
    {
      label: "Relevance",
      value: indexNames.relevance.toString(),
    },
    {
      label: "Newest first",
      value: indexNames.newestFirst.toString(),
    },
    {
      label: "Oldest first",
      value: indexNames.oldestFirst.toString(),
    },
  ]);

  return (
    <Block as={"div"} useHorizontalPaddingAtLowerWidths={false}>
      <div
        css={css`
          align-items: center;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          margin: 0 auto ${sizeLarge} auto;
          max-width: ${layoutWidth1200};

          @media (max-width: ${mediaTabletLandscape}) {
            background: ${colorBlueLightest};
            margin-bottom: 0;
            padding: 0 ${sizeLarge} 1.5rem;
          }
        `}
      >
        <Text as={"span"} color={"gray"} size={"xsmall"}>
          {`${numResults}${nameResults}`.toUpperCase()}
        </Text>
        <SortBy defaultRefinement={sortOptions[0].value} items={sortOptions} />
      </div>
    </Block>
  );
};

export default connectStats(Stats);
