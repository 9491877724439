import {
  boxShadowStandard,
  colorSteelDarkest,
  colorWhite,
  mediaTabletLandscape,
  sizeLarge,
  sizeXxlarge,
  sizeXxxlarge,
} from "@10xdev/design-tokens";
import { css, Global } from "@emotion/react";
import type { FunctionComponent, ReactNode } from "react";
import { createRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import FocusLock from "react-focus-lock";
import { RemoveScroll } from "react-remove-scroll";
import { useKey } from "react-use";

import Icon from "../Icon";
import IconButton from "../IconButton";

interface Props {
  children: ReactNode;
  fullHeight?: boolean;
  handleClose: any;
}

const Modal: FunctionComponent<Props> = ({
  children,
  fullHeight = false,
  handleClose,
}) => {
  const [target, setTarget] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    const newTarget = document.createElement("div");
    newTarget.setAttribute("class", "modal");
    newTarget.setAttribute("role", "dialog");
    newTarget.setAttribute("style", "position: relative; z-index: 1000;");
    newTarget.setAttribute("tabIndex", "-1");
    document.body.appendChild(newTarget);
    setTarget(newTarget);

    return function cleanup() {
      document.body.removeChild(newTarget);
      setTarget(null);
    };
  }, []);

  const closeButtonRef = createRef<HTMLButtonElement>();
  useEffect(() => {
    if (closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
  }, [closeButtonRef]);

  useKey("Escape", handleClose);

  return target
    ? ReactDOM.createPortal(
        <FocusLock returnFocus={true}>
          <RemoveScroll>
            <Global
              styles={css`
                body {
                  overflow: hidden;
                }
              `}
            />
            <div
              css={css`
                background-color: ${colorSteelDarkest};
                bottom: 0;
                left: 0;
                opacity: 0.4;
                position: fixed;
                right: 0;
                top: 0;
              `}
              onClick={handleClose}
              onKeyPress={() => {}}
              role={"button"}
              tabIndex={-1}
            />

            <div
              css={css`
                bottom: 0;
                box-shadow: ${boxShadowStandard};
                left: 0;
                overflow-x: hidden;
                overflow-y: auto;
                position: fixed;
                right: 0;
                top: 0;
              `}
            >
              <div
                css={css`
                  background-color: ${colorWhite};
                  box-sizing: border-box;
                  left: 50%;
                  margin-bottom: ${sizeXxlarge};
                  max-width: 1280px;
                  outline: none;
                  padding: ${sizeXxlarge};
                  pointer-events: all;
                  position: absolute;
                  transform: translate(-50%, ${sizeXxxlarge});
                  width: 88%;

                  @media (max-width: ${mediaTabletLandscape}) {
                    bottom: ${fullHeight ? 0 : "inherit"};
                    left: 0;
                    margin-bottom: 0;
                    max-width: 100%;
                    overflow-y: ${fullHeight ? "scroll" : "inherit"};
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                    right: 0;
                    top: 0;
                    transform: none;
                    width: 100%;
                  }
                `}
                role={"document"}
                tabIndex={0}
              >
                {children}

                <IconButton
                  css={css`
                    position: absolute;
                    right: ${sizeLarge};
                    top: ${sizeLarge};
                  `}
                  onClick={handleClose}
                  ref={closeButtonRef}
                >
                  <Icon color={"blue"} size={"18px"} source={"close"} />
                </IconButton>
              </div>
            </div>
          </RemoveScroll>
        </FocusLock>,

        target,
      )
    : null;
};

export default Modal;
