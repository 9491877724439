import { mediaTabletLandscape, sizeXxlarge } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import FieldList from "./FieldList";
import ViewOnPubmedButton from "./ViewOnPubmedButton";

interface Props {
  expanded: boolean;
  fields: Array<{
    label: string;
    value: string;
  }>;
  label?: string;
  pmid: string;
}

const ArticleInfoAside: FunctionComponent<Props> = ({
  expanded,
  fields,
  label,
  pmid,
}) => {
  return (
    <aside
      aria-label={label}
      css={css`
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding-left: 128px;
        padding-top: ${expanded ? sizeXxlarge : 0};
        width: 40%;

        @media (max-width: ${mediaTabletLandscape}) {
          display: none;
        }
      `}
    >
      {expanded ? <ViewOnPubmedButton pmid={pmid} /> : null}

      <FieldList fields={fields} />
    </aside>
  );
};

export default ArticleInfoAside;
